import {
  AfterViewInit, Component, ElementRef, Input, OnDestroy,
  OnChanges, OnInit, ViewChild, ViewEncapsulation, SimpleChanges,
  Output, EventEmitter
} from '@angular/core';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import Plyr from 'plyr';

import { Exercise, ExerciseMedia, ExerciseMediaService, ExerciseService, LoadStatus } from 'sp-core';

import { loadAnimation } from '../../../animations';
import { of } from 'rxjs';

@Component({
  selector: 'sp-exercise-media',
  templateUrl: './exercise-media.component.html',
  styleUrls: ['./exercise-media.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    loadAnimation
  ]
})
export class ExerciseMediaComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {

  @Input() exercise: Exercise;

  @Input() playStopOption = false;

  @Input() avoidMouseEnterLeave = false;

  @Input() videoControlsHidden = false;

  /**
   * Establece si con el click sobre el video éste se detendrá.
   * Para cuando el componente padre realizará alguna acción y requiere que el video se detenga.
   * Sólo aplica para videos SPF. En videos youtube/vimeo no se detendrá para permitir al usuario interactuar con los controles del reproductor
   */
  @Input() stopOnMediaClick = false;

  @Input() muted = true;

  @Input() isPrinting: boolean = false;

  @Output() mediaClick = new EventEmitter<PointerEvent>();

  @ViewChild('playerContainer') playerContainerRef: ElementRef;

  player: Plyr;

  LoadStatus = LoadStatus;

  currentLoadStatus = LoadStatus.pending;

  currentVideoStatus = LoadStatus.pending;

  currentThumbnailStatus = LoadStatus.pending;

  showThumbnail = false;

  media: ExerciseMedia;

  isPlaying = false;

  private timeout: NodeJS.Timeout;

  constructor(
    private exerciseService: ExerciseService,
    private exerciseMediaService: ExerciseMediaService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['exercise'] && this.exercise) {
      this.media = this.exercise.defaultMedia?.clone();
      // TODO: Implementar actualización de thumbnail y video para evitar tener que cambiar la instancia completa de exercise
    }
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngAfterViewInit(): void {

    // Si se indicó que el video no se está reproduciendo no se solicita crear el reproductor
    if (!this.exercise.isMediaPlaying  || this.isPrinting) return;

    this.currentVideoStatus = LoadStatus.pending;
    setTimeout(() => {  // Existía un error: ExpressionChangedAfterItHasBeenCheckedError debido a que cambiaba de estatus pending a loading en muy corto tiempo
      this.playVideo();
    });
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.destroy();
    }
  }

  onMouseEnterLeave(play = true): void {

    // Si se indicó omitir los eventos enter y leave
    if (this.avoidMouseEnterLeave) return;

    // El evento hover para reproducir el video no aplica para youtube/ vimeo
    if (this.media?.isExternalMedia) return;

    // Elimina tiempo de espera previo si aplica
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // Cuando es para reproducir el video se espera 0.5s
    // Cuando es para pausar únicamente un pequeño tiempo de espera para asegurar en lo posible que se pueda detectar el mouseleave. En ocasiones no se detectaba dicha acción
    const timeout = play ? 500 : 150;
    this.timeout = setTimeout(() => {
      if (play) {
        this.playVideo();
      } else {
        this.stopVideo();
      }
    }, timeout);
  }

  handleThumbnailClick(e: PointerEvent): void {

    if (this.media.isSPF) {
      if (!this.stopOnMediaClick) this.playVideo();
      this.mediaClick.emit(e);
    } else {
      // Cuando se trata de un video youtube/ vimeo no emite el click al componente padre
      // Para que en dado caso el usuario pueda interactuar con los controles del reproductor de dicho video
      e.stopPropagation();
      this.playVideo();
    }
  }

  handleVideoClick(e: PointerEvent): void {

    if (this.media.isSPF) {
      if (this.stopOnMediaClick) this.stopVideo();
      this.mediaClick.emit(e);
    } else {
      // Cuando se trata de un video youtube/ vimeo no emite el click al componente padre
      // Para que en dado caso el usuario pueda interactuar con los controles del reproductor de dicho video
      e.stopPropagation();
    }
  }

  playVideo(): void {

    // Oculta thumbnail
    this.hideThumbnail();

    // Verifica si el reproductor de video está cargado. En ese caso sólo indica que inicie a reproducir
    if (this.player) {
      this.player.play();
      return;
    }

    // Solicita crear el reproductor y posteriormente inicia reproducción (Se le indica autoplay porque no se iniciaba el reproductor)
    this.loadVideoPlayer().then();
  }

  stopVideo(): void {
    if (!this.player?.playing) return;
    setTimeout(() => {
      this.player.stop();
    });
  }

  pauseVideo(): void {
    if (!this.player?.playing) return;
    setTimeout(() => {
      this.player.pause();
    });
  }

  initialize(): void {

    if (this.player) {
      this.player.destroy(() => {
        this.player = null;
        this.isPlaying = false;
        this.initializeThumbnail();
      });
    }
    else {
      this.initializeThumbnail();
    }

  }

  private initializeThumbnail(): void {
    // En caso de que se haya indicado que se está reproduciendo el video no tiene caso mostrar thumbnail, por lo que se evita se cree el elemento img
    this.showThumbnail = !this.exercise.isMediaPlaying || this.isPrinting;
    if (this.showThumbnail) {
      this.currentLoadStatus = LoadStatus.loading;
      this.loadThumbnail().then(() => {
        setTimeout(() => {
          this.currentLoadStatus = LoadStatus.loaded;
        });
      });
    } else {
      this.currentLoadStatus = LoadStatus.loaded;
    }
  }

  private loadThumbnail(): Promise<void> {

    return new Promise(resolve => {

      const media = this.media;

      if (!media || !media.videoId) {
        this.currentThumbnailStatus = LoadStatus.loaded;
        resolve();
        return;
      }

      // Si se indica que el video está reproduciendo no es necesario obtener thumbnail
      if (this.exercise.isMediaPlaying) {
        this.currentThumbnailStatus = LoadStatus.loaded;
        resolve();
        return;
      }

      // Antes de solicitar obtener el thumbnail realiza validaciones previas
      // - Si el video es de SoloPerformance ya debe tener asignado thumbail
      // - O si tiene thumbnail asignado no es necesario obtenerlo de nuevo
      if (media.isSPF || media.thumbnail) {
        // Ya tiene thumbnail por lo que no se requiere obtener
        this.currentThumbnailStatus = LoadStatus.loaded;
        resolve();
        return;
      }

      // Obtiene thumbnail
      this.currentThumbnailStatus = LoadStatus.loading;
      this.exerciseMediaService.getVideosThumbnails(
        [media]
      ).pipe(
        catchError(error => {
          throw error;
        }),
        // Asigna el thumbnail obtenido para agilizar su consulta posterior. No se necesitará consultar de API la siguiente vez
        mergeMap(() => {
          if (!this.exercise.id || !media.thumbnail) return of(true);
          return this.exerciseService.updateThumbnail(this.exercise.id, media.thumbnail)
        }),
        catchError(error => {
          throw error;
        }),
        finalize(() => {
          this.currentThumbnailStatus = LoadStatus.loaded;
          resolve();
        })
      ).subscribe(() => {
        // Asigna thumbnail obtenido
        this.exercise.defaultMedia.thumbnail = media.thumbnail;
      }, error => {
        this.showThumbnail = false;
        resolve();
      });
    });
  }

  private hideThumbnail(): void {
    this.currentThumbnailStatus = LoadStatus.pending;
  }

  /**
   * Carga el reproductor de video
   * @param autoplay  TODO: Al crear el reproductor y posteriormente llamar su método play no se iniciaba el video
   */
  private loadVideoPlayer(
    autoplay = true
  ): Promise<void> {

    return new Promise(resolve => {

      const playerContainer = this.playerContainerRef?.nativeElement as HTMLDivElement;
      if (!playerContainer) {
        resolve();
        return;
      }

      if (!this.media) {
        resolve();
        return;
      }

      if (this.player) {
        resolve();
        return;
      }

      this.currentVideoStatus = LoadStatus.loading;

      // Inicializa reproductor de video
      this.player = new Plyr(playerContainer.querySelector('video'), {
        autoplay: autoplay,
        disableContextMenu: true,
        controls: this.media.isSPF
          ? []
          : ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
        // Para evitar error: Failed to execute 'postMessage' on 'DOMWindow': The target origin provided ('https://www.youtube.com') does not match the recipient window's origin ('http://localhost:...
        youtube: {
          origin: 'https://www.youtube.com'
        }
      });

      // Set muted option. In new Plyr initialization the mute option don't work
      this.player.muted = this.muted;

      // Asigna video a reproductor
      this.player.source = {
        type: 'video',
        sources: [
          {
            type: this.media.isSPF
              ? 'video/mp4'
              : undefined,
            provider: this.media.isSPF
              ? undefined
              : (this.media.provider as Plyr.Provider),
            src: this.media.path
          }
        ]
      }

      // Espera hasta que el video se haya asignado para indicar que se ha cargado 
      this.player.on('playing', () => {
        this.isPlaying = true;
      });

      // Espera hasta que el video se haya asignado para indicar que se ha cargado 
      this.player.on('pause', () => {
        this.isPlaying = false;
      });

      // Espera hasta que el video se haya asignado para indicar que se ha cargado 
      this.player.once('loadedmetadata', () => {
        this.currentVideoStatus = LoadStatus.loaded;
        resolve();
      });

      // Si sucediera algún error no controlado
      this.player.once('error', (event) => {
        this.currentVideoStatus = LoadStatus.loaded;
        console.log(`Error: ${event.detail}`);
        resolve();
      });
    });
  }
}