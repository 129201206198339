import moment from "moment";

import { ProgramType, PublishStatus } from "./enumerations";
import { ProgramCreateRequest, ProgramResponse, Serializer } from './interfaces';

import { AuditUser } from "./audit-user";
import { Phase } from './phase';
import { Team } from "./team";
import { Athlete } from "./athlete";
import { Workout } from "./workout";
import { FolderBlockData } from "./folder-block-data";

const PROGRAM_DEFAULT_NAME = "Program's name";

export class Program implements Serializer<Program>{

    /**
     * Objeto para verificar valores previos a una modificación y permitir enviar sólo los campos modificados
     */
    private initialValues: Program;

    get athletesHasChanges(): boolean {

        if (this.athletes.length !== this.initialValues.athletes.length) return true;

        const athletesIds = this.athletes.map(x => x.id).sort((a, b) => a - b);
        const initialAthletesIds = this.initialValues.athletes.map(x => x.id).sort((a, b) => a - b);
        return athletesIds.join() !== initialAthletesIds.join();
    }

    get type(): ProgramType {
        if (this.isMarketplace) return ProgramType.marketplace;
        if (this.hasLibrary) return ProgramType.template;
        if (this.isPlaylist) return ProgramType.playlist;
        return ProgramType.scheduled;
    }

    get defaultName(): string {
        return this.name || PROGRAM_DEFAULT_NAME;
    }

    /**
     * Primer equipo asignado
     */
    get team(): Team {
        if (!this.teams.length) return null;
        return this.teams[0];
    }

    /**
     * Obtiene todos los workouts del programa recorriendo sus fases, semanas y días
     */
    get workouts(): Array<Workout> {

        if (!this.phases.length) return [];

        const weeks = this.phases.map(x => x.weeks).reduce((a, b) => a.concat(b), []);
        if (!weeks.length) return [];

        const days = weeks.map(x => x.days).reduce((a, b) => a.concat(b), []);
        if (!days.length) return [];

        return days.map(x => x.workouts).reduce((a, b) => a.concat(b), []);;
    }

    get foldersHasChanges(): boolean {
        if (this.folder?.length !== this.initialValues.folder?.length) return true;
        const foldersIds = this.folder;
        const initialFoldersIds = this.initialValues.folder;
        return foldersIds.join() !== initialFoldersIds.join();
    }

    constructor(
        public id?: number,
        public name?: string,
        /**
         * Fecha de inicio del programa
         */
        public starts?: Date,
        /**
         * Fecha de finalización del programa
         */
        public ends?: Date,
        public duration?: string,
        public daysPerWeek?: number,
        public isPlaylist?: boolean,
        /**
         * Indica o establece si el programa es de tipo template
         */
        public hasLibrary?: boolean,
        public isMarketplace?: boolean,
        public isAcquired?: boolean,
        public status?: PublishStatus,
        public audit?: AuditUser,
        /**
         * Equipos asignados
         */
        public teams: Array<Team> = [],
        /**
         * Atletas asignados
         */
        public athletes: Array<Athlete> = [],
        /**
         * Fases del programa
         */
        public phases: Array<Phase> = [],
        public url?: string,
        public folders: Array<number> = [],
        public folder: Array<FolderBlockData> = [],

    ) {
        // Se inicializan los valores iniciales sólo indicado la estructura para evitar instanciar y generar max stack
        this.initialValues = {} as Program;
        this.setInitialValues(this);
    }

    static fromResponse(response: ProgramResponse): Program {

        const program = new Program(
            response.id,
            response.name ? response.name : (response.program_name ? response.program_name : null),
            null,
            null,
            response.duration ? response.duration : null,
            response.days_per_week ? +response.days_per_week : null,
            response.playlist || false,
            response.has_library || false,
            response.marketplace || false,
            response.acquired || false,
            response.status ? response.status as PublishStatus : null,
            new AuditUser().fromResponse(response),
            response.team ? response.team.map(x => new Team().fromResponse(x)) : [],
            response.athletes ? response.athletes.map(x => new Athlete().fromResponse(x)) : [],
            response.phases ? response.phases.map(x => new Phase().fromResponse(x)) : [],
            response?.url
        );

        // Fecha de inicio
        if (response.starts) {
            let starts = moment(response.starts, 'MM-DD-YYYY');
            // Si la fecha no es válida intenta con otro formato
            if (!starts.isValid()) {
                starts = moment(response.starts, 'YYYY-MM-DD');
            }
            if (starts.isValid()) {
                program.starts = starts.toDate();
            }
        }

        // Fecha final
        if (response.ends) {
            let ends = moment(response.ends, 'MM-DD-YYYY');
            // Si la fecha no es válida intenta con otro formato
            if (!ends.isValid()) {
                ends = moment(response.ends, 'YYYY-MM-DD');
            }
            if (ends.isValid()) {
                program.ends = ends.toDate();
            }
        }

        program.folders = response?.folder;

        program.setInitialValues(program);

        return program;
    }

    fromResponse(response: ProgramResponse): Program {
        return Program.fromResponse(response);
    }

    toRequest(): ProgramCreateRequest {
        return <ProgramCreateRequest>{
            name: this.name,
            athletes_id: this.athletes.map(x => x.id),
            teams_id: this.teams.map(x => x.id),
            folder: this.foldersHasChanges
                ? this.folder.map(f => f.id)
                : []
        }
    }

    clone(): Program {
        return new Program(
            this.id,
            this.name,
            this.starts,
            this.ends,
            this.duration,
            this.daysPerWeek,
            this.isPlaylist,
            this.hasLibrary,
            this.isMarketplace,
            this.isAcquired,
            this.status,
            this.audit.clone(),
            this.teams.slice(),
            this.athletes.slice()
        )
    }

    /**
     * Aplica los valores actuales del objeto para una siguiente validación de cambios
     */
    applyChanges(): void {
        this.setInitialValues(this);
    }

    /**
     * Asigna valores iniciales a los campos de validación de cambios
     * @param program Programa con los datos actuales
     */
    private setInitialValues(program: Program): void {
        this.initialValues.athletes = program.athletes.slice();
        this.initialValues.folder = program.folder?.map(x => x);

    }
}
