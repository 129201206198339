import { Component, DoCheck, HostListener, Inject, IterableDiffer, IterableDiffers, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { Observable, of } from 'rxjs';
import { mergeMap, finalize, delay, switchMap, filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import {
  Application, Exercise, ExerciseMedia, ExerciseMediaService, ExerciseService, ExerciseSubcategory, FolderBlockData, FolderService, FolderType, Media,
  ProgressSpinnerService, PublishStatus, ServiceResponse, UserService
} from 'sp-core';

import { CrudService } from '../../../services';
import { NotificationService } from '../../sp-notification';

import { ExerciseDetailConfig } from '../models';
import { EXERCISE_DETAIL_CONTROL_NAMES } from './exercise-detail.constants';
import { ExerciseDetailData } from './exercise-detail-data';
import { ExerciseDetailValidators } from './exercise-detail-validators';
@Component({
  selector: 'sp-exercise-detail',
  templateUrl: './exercise-detail.component.html',
  styleUrls: ['./exercise-detail.component.scss']
})
export class ExerciseDetailComponent implements OnInit, OnDestroy, DoCheck {

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent): void {

    if (!this.withNavigation || this.spinnerIsRunning) return;

    if (e.ctrlKey && e.code.toLowerCase() === 'arrowleft') {
      this.handlePreviousClick();
    } else if (e.ctrlKey && e.code.toLowerCase() === 'arrowright') {
      this.handleNextClick();
    }
  }

  get subcategoriesCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.subcategories);
  }

  get namesForm(): UntypedFormGroup {
    return this.form.get(this.CONTROL_NAMES.namesGroup) as UntypedFormGroup;
  }

  get englishNameCtrl(): AbstractControl {
    return this.namesForm.get(this.CONTROL_NAMES.namesGroupEnglish);
  }

  get spanishNameCtrl(): AbstractControl {
    return this.namesForm.get(this.CONTROL_NAMES.namesGroupSpanish);
  }

  get pathYoutubeCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.pathYoutube);
  }

  get pathCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.path);
  }

  get pathFileCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.pathFile);
  }

  get foldersCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.folders);
  }

  Application = Application;

  currentApplication: Application;

  exercise: Exercise;

  exerciseMedia: Exercise;

  exercisesNavigation: Array<Exercise> = [];
  foldersList: Array<FolderBlockData> = [];
  foldersListSelected: Array<FolderBlockData> = [];

  config: ExerciseDetailConfig;

  isFirst = true;
  isLast = false;
  currentNavigationIndex = 0;

  form: UntypedFormGroup;

  selectedSubcategoriesId: Array<number> = [];

  CONTROL_NAMES = EXERCISE_DETAIL_CONTROL_NAMES;

  PublishStatus = PublishStatus;

  spinnerIsRunning = false;

  externalMedia: ExerciseMedia;

  withExternalMedia = false;

  readonly = false;

  isAdminUser = false;

  private subSink = new SubSink();

  private iterableDiffer: IterableDiffer<Exercise>;

  /**
   * Obtiene si se tendrá opción de navegación
   */
  private withNavigation = false;

  mediaUrlTimeout: NodeJS.Timeout;

  constructor(
    private iterableDiffers: IterableDiffers,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private fb: UntypedFormBuilder,
    private CRUD: CrudService,
    private spinnerService: ProgressSpinnerService,
    private notificationService: NotificationService,
    private exerciseService: ExerciseService,
    private exerciseMediaService: ExerciseMediaService,
    private folderService: FolderService,
    private dialog: MatDialogRef<ExerciseDetailComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: ExerciseDetailData,
  ) {

    // Inicia spinner para bloquear interacción. Se detendrá hasta que la pantalla esté lista para interactuar
    this.spinnerService.start();

    // Para identificar cambio de elementos del array de ejercicios junto con el evento ngDoCheck
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);

    this.iconRegistry.addSvgIcon('navigate-before', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/navigate-before.svg'));
    this.iconRegistry.addSvgIcon('navigate-next', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/navigate-next.svg'));

    if (data) {
      this.currentApplication = data.application;
      this.exercise = data.exercise || new Exercise();
      this.exercisesNavigation = data.exercisesNavigation || [];
      this.config = this.data.config;
    } else {
      this.currentApplication = Application.admin;
      this.exercise = new Exercise();
    }

    this.withNavigation = !!this.exercisesNavigation.length;
    if (this.withNavigation) {
      this.currentNavigationIndex = this.exercisesNavigation.indexOf(this.exercise);
      this.navigationCheckBounds();
    }

    this.selectedSubcategoriesId = this.exercise.subcategoryIds;

    this.createForm(this.exercise);
  }

  ngDoCheck(): void {
    // Escucha cambios en el arreglo de ejercicios. Por tema de paginación se pueden agregar más ejercicios en el listado (exercise-card-list)
    if (this.withNavigation) {
      const changes = this.iterableDiffer.diff(this.exercisesNavigation);
      if (changes) {
        this.navigationCheckBounds(false);
      }
    }
  }

  ngOnInit(): void {

    this.subSink.sink = this.subSink.sink = this.userService.user$.pipe(
      filter(x => !!x)
    ).subscribe(authenticatedUser => {
      this.isAdminUser = authenticatedUser.isAdmin;
      // Detiene spinner hasta que el usuario se haya obtenido
      this.spinnerService.stop();
      this.checkIfReadonly();
    });

    this.subSink.sink = this.spinnerService
      .stateChange
      .subscribe(state => this.spinnerIsRunning = state);

    this.subSink.sink = this.folderService.getPaginatedFolders( null, FolderType.EXERCISE ).pipe(map(resp=>resp.data)).subscribe( (folders: any) => {
      this.foldersList = folders;
      this.selectFolders(this.data?.exercise?.folder);
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  /**
   * Elimina el ejercicio .
   * @author Martin Batun Tec.
  */
  handleDeleteClick(): void {
    this.CRUD.delete('exercises', this.exercise);
    this.dialog.close();
  }

  handlePreviousClick(): void {
    this.navigateToExercise(-1);
  }

  handleNextClick(): void {
    this.navigateToExercise(1);
  }

  handleSaveClick(): void {
    this.save();
  }

  /**
   * Publica o despublica el ejercicio.
   * @author Martin Batun Tec.
  */
  handlePublishUnpublishClick(): void {
    const statusToAssign = this.exercise.status === PublishStatus.empty ? PublishStatus.notVerified : PublishStatus.empty;
    this.spinnerService.start();
    this.exerciseService.setPublishStatus(
      this.exercise.id, statusToAssign
    ).pipe(
      finalize(() => this.spinnerService.stop())
    ).subscribe(item => {
      this.exercise.status = statusToAssign;
      this.CRUD.msg(this.exercise.status === PublishStatus.notVerified ? 'Published' : 'Unpublished');
      this.dialog.close();
    }, (error: ServiceResponse) => {
      this.notificationService.error(error.errorMessage.firstError);
    });
  }

  handleExerciseFilterChange(subcategories: Array<ExerciseSubcategory>) {
    this.subcategoriesCtrl.setValue(subcategories.map(x => x.id));
  }

  handleApproveRejectClick(status: PublishStatus): void {
    this.spinnerService.start();
    this.exerciseService.setPublishStatus(
      this.exercise.id, status
    ).pipe(
      finalize(() => this.spinnerService.stop())
    ).subscribe(item => {
      this.CRUD.action.next({ item: item, type: 'delete' });
      this.CRUD.msg(status == PublishStatus.verified ? 'Exercise aproved' : 'Exercise rejected')
      // Send to afterClosed subscribed the url because needs refresh the bage notifier.
      this.dialog.close(status == PublishStatus.verified ? 'aprove' : 'reject');
    }, (error: ServiceResponse) => {
      this.notificationService.error(error.errorMessage.firstError);
    });
  }

  handleUploadVideoFile(file: File): void {
    this.withExternalMedia = false;
    this.pathFileCtrl.setValue(file);
  }

  checkPublishUnpublishOption(): boolean {
    return !this.exercise.hasLibrary // Sólo se habilita para los ejercicios que NO son librería
      && this.exercise && this.exercise.id // Se habilita sólo si ya existe previamente el ejercicio
      && (this.exercise.status === PublishStatus.empty || this.exercise.status === PublishStatus.notVerified);  // Sólo se habilita cuando aún no está aprobado o rechazado
  }

  private navigateToExercise(direction = 1): void {
    if ((this.isFirst && direction === -1) || (this.isLast && direction === 1)) return;
    this.currentNavigationIndex += direction;
    this.exercise = this.exercisesNavigation[this.currentNavigationIndex];
    this.setValuesToForm(this.exercise);
    this.checkIfReadonly();
    this.navigationCheckBounds();
  }

  private navigationCheckBounds(emit = true): void {
    this.isFirst = this.currentNavigationIndex === 0;
    this.isLast = this.currentNavigationIndex === this.exercisesNavigation.length - 1;
    if (emit && this.isLast && this.config.reachEnd) {
      this.config.reachEnd();
    }
  }

  private createForm(exercise: Exercise): void {

    // Grupo de formulario para nombres español e inglés
    const namesForm = this.fb.group({});
    namesForm.addControl(this.CONTROL_NAMES.namesGroupEnglish, this.fb.control(exercise.englishName));
    namesForm.addControl(this.CONTROL_NAMES.namesGroupSpanish, this.fb.control(exercise.spanishName));
    namesForm.setValidators([ExerciseDetailValidators.oneNameRequired]);

    this.form = this.fb.group({
      [this.CONTROL_NAMES.folders] : [this.data?.exercise?.folder]
    });
    this.form.addControl(this.CONTROL_NAMES.path, new UntypedFormControl(exercise.defaultMedia?.path));
    this.form.addControl(this.CONTROL_NAMES.pathFile, new UntypedFormControl(null));

    // Video path
    const media = exercise.defaultMedia;
    const pathYoutubeControl = this.fb.control(media?.isExternalMedia ? media.path : null);
    pathYoutubeControl.valueChanges.pipe(
      // Por cada valor que el usuario capture se espera medio segundo antes de emitirlo al subscribe
      // Por cada valor que se capture el switchMap cancela la emisión del valor anterior (que está en espera de los 500ms) por lo que al final sólo el último valor se emitirá al subscribe
      switchMap(value => of(value).pipe(delay(500)))
    ).subscribe((value: string) => {
      this.loadExternaMedia(exercise, value);
    })
    this.form.addControl(this.CONTROL_NAMES.pathYoutube, pathYoutubeControl);

    this.form.addControl(this.CONTROL_NAMES.namesGroup, namesForm);
    this.form.addControl(this.CONTROL_NAMES.subcategories, new UntypedFormControl(exercise.subcategoryIds));
    this.form.get(this.CONTROL_NAMES.folders).valueChanges.subscribe( folders =>{
      this.selectFolders(folders);
    })
  }

  private loadExternaMedia(
    exercise: Exercise,
    url: string
  ): void {

    this.withExternalMedia = false;
    this.exerciseMedia = null;

    // Si la url indicada es la misma de la media que tiene asignado el ejercicio le asigna éste. Para el caso en que carga el ejercicio
    const externalMedia = (this.exercise.externalMedia?.path === url)
      ? this.exercise.externalMedia
      : (ExerciseMedia.fromUrl(url));

    // En caso de que se haya asignado videoId entonces si es 
    if (externalMedia?.isExternalVideoValid) {

      this.withExternalMedia = true;
      this.externalMedia = externalMedia;

      // Update the exercise media variable to refresh the exercise media component and show the video
      const exerciseToSend = exercise.clone();
      exerciseToSend.medias = [externalMedia];
      setTimeout(() => {
        this.exerciseMedia = exerciseToSend;
      });
    }
  }

  private setValuesToForm(exercise: Exercise): void {
    this.englishNameCtrl.setValue(exercise.englishName);
    this.spanishNameCtrl.setValue(exercise.spanishName);

    this.pathCtrl.setValue(exercise.defaultMedia?.path);
    this.pathYoutubeCtrl.setValue(exercise.path_youtube);
    this.subcategoriesCtrl.setValue(exercise.subcategoryIds);
  }

  private save(): void {

    if (this.form.invalid) {
      this.form.updateValueAndValidity();
      return;
    }

    // Ejercicio a guardar
    const exerciseToSave = new Exercise();
    exerciseToSave.hasLibrary = this.isAdminUser; //validación para que las modificaciones hechas por el admin funcione bien
    exerciseToSave.englishName = this.englishNameCtrl.value;
    exerciseToSave.spanishName = this.spanishNameCtrl.value;
    exerciseToSave.subcategoryIds = this.subcategoriesCtrl.value;
    exerciseToSave.folder = this.foldersListSelected?.map(({id})=>id);

    // Solicitud de subida de video del ejercicio
    let uploadVideoRequest: Observable<Media>;
    if (this.withExternalMedia && this.externalMedia) {
      // Obtiene el thumbnail del video
      uploadVideoRequest = this.exerciseMediaService.getVideosThumbnails(
        [this.externalMedia]
      ).pipe(
        // TODO: Controlar si sucede error no continuar con el guardado
        switchMap(() => {
          exerciseToSave.medias = [this.externalMedia];
          return of(null)
        })
      );
    }
    else if (this.pathFileCtrl.value) {
      uploadVideoRequest = this.exerciseService.uploadVideo(Media.fromFile(this.pathFileCtrl.value));
    } else {
      uploadVideoRequest = of(null);
    }

    this.spinnerService.start();
    uploadVideoRequest.pipe(
      mergeMap(uploadMediaData => {

        // Se mapea información de la media recién subida
        if (uploadMediaData) {
          exerciseToSave.medias.push(ExerciseMedia.fromMedia(uploadMediaData));
        }

        // Verifica si es un ejercicio nuevo para crearlo
        if (this.exercise.isNew) {
          return this.config.create ? this.config.create(exerciseToSave) : of(exerciseToSave);
        }
        // En caso contrario actualiza
        else {
          return this.config.update ? this.config.update(this.exercise.id, exerciseToSave) : of(exerciseToSave);
        }
      })
    ).pipe(
      finalize(() => this.spinnerService.stop())
    ).subscribe(exerciseResponse => {
      this.CRUD.response(exerciseResponse, this.exercise.isNew ? 'add' : 'edit', 'Exercise')
      this.dialog.close(exerciseResponse);
    }, (error: ServiceResponse) => {
      this.notificationService.error(error.errorMessage.firstError);
    });
  }

  /**
   * Revisa si el formulario debería ser sólo lectura. Llamar por cada cambio de ejercicio y de usuario autenticado
   * @param exercise 
   */
  private checkIfReadonly(): void {
    // Si el ejercicio es de SoloPerformance y el usuario en sesión NO es Admin, evita su edición
    this.readonly = (this.exercise.isSPF && !this.isAdminUser);
    if (this.readonly) this.form.disable();
    else this.form.enable();
  }

  private selectFolders(folders: number[] = []){
    this.foldersListSelected = this.foldersList.filter(folder=>{
      const isInclude = folders.includes(folder.id);
      isInclude ? folder.select() : folder.unselect();
      return isInclude;
    })
  }
}
