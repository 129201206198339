import { ExerciseMediaResponse, ExerciseResponse, Serializer } from './interfaces';

import { AuditUser } from './audit-user';
import { ExerciseMedia } from './exercise-media';
import { PublishStatus } from './enumerations';
import { InitialValues } from './abstracts';

export class Exercise extends InitialValues<Exercise> implements Serializer<Exercise> {

    get name(): string {
        const _language = localStorage.getItem('USER_LANGUAGE');
        const _name = _language == 'es' ? this.spanishName || this.englishName : this.englishName || this.spanishName; 
        return  _name;
    }

    /**
     * Obtiene el objeto media por defecto del ejercicio (El primer objeto media). Video o imagen del ejercicio
     * @returns Objeto media con los datos de video o imagen del ejercicio
     */
    get defaultMedia(): ExerciseMedia {
        if (!this.medias.length) return null;
        return this.medias[0];
    }

    get externalMedia(): ExerciseMedia {

        if (!this.medias.length) return null;

        return this.medias.find(x => x.isExternalMedia);
    }

    /**
     * Obtiene si el ejercicio es nuevo. En caso de que el ejercicio tenga id asignado significa que ya existe
     */
    get isNew(): boolean {
        return !this.id;
    }

    /**
     * Obtiene si el ejercicio es propio de Solo Performance, es decir no la creó algún usuario
     * ```
     * Si el ejercicio es de Solo Performance únicamente un usuario con rol Admin podría modificarlo
     * ```
     */
    get isSPF(): boolean {
        return this.hasLibrary;
    }

    /**
     * Obtiene si la colección de medias (videos) tiene cambios
     * - Si tiene diferente número de registros lo considera con cambios
     * - Si tienen el mismo número de registros verifica se tengan los mismos ids
     */
    get mediasHasChanges(): boolean {

        if (this.medias.length !== this.initialValues.medias.length) return true;

        const mediasIds = this.medias.map(x => x.id).sort((a, b) => a - b);
        const initialMediasIds = this.initialValues.medias.map(x => x.id).sort((a, b) => a - b);
        return mediasIds.join() !== initialMediasIds.join();
    }

    /**
     * Obtiene si la colección de subcategorías tiene cambios
     * - Si tiene diferente número de registros lo considera con cambios
     * - Si tienen el mismo número de registros verifica se tengan los mismos valores
     */
    get subcategoriesIdsHasChanges(): boolean {

        if (this.subcategoryIds.length !== this.initialValues.subcategoryIds.length) return true;

        const subcategoryIds = this.subcategoryIds.sort((a, b) => a - b);
        const initialSubcategoryIds = this.initialValues.subcategoryIds.sort((a, b) => a - b);
        return subcategoryIds.join() !== initialSubcategoryIds.join();
    }

    get hasChanges() {
        return this.initialValues.englishName !== this.englishName
            || this.initialValues.spanishName !== this.spanishName
            || this.initialValues.isActive !== this.isActive
            || this.initialValues.hasLibrary !== this.hasLibrary
            || this.initialValues.note !== this.note
            || this.initialValues.requestLibrary !== this.requestLibrary
            || this.initialValues.status !== this.status
            || this.initialValues.folder !== this.folder
            || this.mediasHasChanges
            || this.subcategoriesIdsHasChanges;
    }

    constructor(
        public id?: number,
        public englishName?: string,
        public spanishName?: string,
        public isActive?: boolean,
        public hasLibrary?: boolean,
        public note?: string,
        public requestLibrary?: boolean,
        public audit?: AuditUser,
        public medias: Array<ExerciseMedia> = [],
        public subcategoryIds: Array<number> = [],
        /**
         * Obtiene o establece si el archivo media se está reproduciendo.
         * NOTA: No se obtiene por backend, se procesa en frontend
         */
        public isMediaPlaying = false,
        /**
         * Obtiene o establece la URL correspondiente a un video youtube o vimeo
         * @deprecated  Se agrega un objeto media a la colección medias, en base al path o URL del video youtube/vimeo que se asignaba en ésta propiedad
         */
        public path_youtube?: string,
        public status?: PublishStatus,
        public folder?: number[]
    ) {
        super();

        // Se inicializan los valores sólo indicado la estructura para evitar instanciar y generar max stack
        this.initialValues = {} as Exercise;
        this.initialValues.audit = {} as AuditUser;
        this.setInitialValues(this);
    }

    fromResponse(response: ExerciseResponse): Exercise {
        return Exercise.fromResponse(response);
    }

    static fromResponse(response: ExerciseResponse): Exercise {

        const exercise = new Exercise(
            response.id,
            response.english_name,
            response.spanish_name,
            response.active ? response.active : false,
            response.has_library ? response.has_library : false,
            response.note ? response.note : null,
            response.request_library ? response.request_library : false,
            new AuditUser().fromResponse(response),
            [],
            response.sub_category ? response.sub_category : [],
            false,
            response.path_youtube,
            response.status as PublishStatus,
            response.folder
        );

        // Medias
        if (response.media?.length) {
            exercise.medias.push(...response.media.map(x => new ExerciseMedia().fromResponse(x)));
        }

        // 
        if (response.path_youtube) {
            const youtubeMedia = ExerciseMedia.fromUrl(response.path_youtube);
            if (response.thumbnail_youtube) {
                youtubeMedia.thumbnail = response.thumbnail_youtube;
            }
            exercise.medias.push(youtubeMedia);
        }

        return exercise;
    }

    toRequest(): ExerciseResponse {

        const request = <ExerciseResponse>{
            has_library: this.hasLibrary || false,
            english_name: this.englishName,
            spanish_name: this.spanishName,
            sub_category: this.subcategoryIds,
            note: this.note,
            media: [],
            folder: this.folder
        }

        if (this.medias.length) {

            // Videos SPF
            this.medias.filter(x => x.isSPF).forEach(exerciseMedia => {
                request.media.push(
                    <ExerciseMediaResponse>{
                        path: exerciseMedia.path,
                        thumbnail: exerciseMedia.thumbnail
                    }
                );
            });

            // Si se tiene video youtube/vimeo lo descompone y asigna en los campos correspondientes
            const media = this.externalMedia;
            if (media) {
                request.path_youtube = media.path;
                request.thumbnail_youtube = media.thumbnail;
            }
        }

        return request;
    }

    clone(): Exercise {
        return new Exercise(
            this.id,
            this.englishName,
            this.spanishName,
            this.isActive,
            this.hasLibrary,
            this.note,
            this.requestLibrary,
            this.audit, // TODO: Clonar
            this.medias,    // TODO: Clonar
            this.subcategoryIds,    // TODO: Clonar
            false,
            this.path_youtube,
            null,
            this.folder
        )
    }

    applyChanges(): void {
        this.setInitialValues(this);
    }

    protected setInitialValues(exercise: Exercise): void {
        this.initialValues.englishName = exercise.englishName;
        this.initialValues.spanishName = exercise.spanishName;
        this.initialValues.isActive = exercise.isActive;
        this.initialValues.hasLibrary = exercise.hasLibrary;
        this.initialValues.note = exercise.note;
        this.initialValues.requestLibrary = exercise.requestLibrary;
        this.initialValues.medias = exercise.medias.slice();
        this.initialValues.subcategoryIds = exercise.subcategoryIds.slice();
        this.initialValues.status = exercise.status;
        this.initialValues.folder = exercise.folder;
    }
}
