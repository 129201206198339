import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SP_CORE_CONFIG } from '../sp-core.constants';
import { FolderType } from '../models/enumerations/folder-type.enum';
import { SpCoreConfig } from '../sp-core-config.interface';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { FoldersBlockData } from '../models/folders-block-data';
import { PaginatedParams } from '../models';
import { Observable } from 'rxjs';
import { FoldersBlockDataResponse } from '../models/interfaces/folders-block-data-response.interface';

@Injectable()
export class FolderService {

  private API_KEY: string;

  constructor(
    @Inject(SP_CORE_CONFIG) private config: SpCoreConfig,
    private authService: AuthService,
    private api: ApiService
  ) {
    this.API_KEY = this.config.youtubeApiKey;
  }

  getPaginatedFolders(
    search?: string,
    folder_type?: FolderType,
    paginatedParams?: PaginatedParams
    ): Observable<FoldersBlockData>  {

      let params = new HttpParams().set('institution', this.authService.institutionId.toString())

      if (search) {
        params = params.set('search', search);
      }
  
      if (folder_type) {
        params = params.set('folder_type', folder_type);
      }
  
      if (paginatedParams) {
        params = params
          .set('perpage', paginatedParams.pageSize)
          .set('page', paginatedParams.page);
      }

    return this.api.get<FoldersBlockDataResponse>(
      `folder-library/`,
      params
    ).pipe(
      map(response => new FoldersBlockData().fromResponse(response))
    );
  }
}
