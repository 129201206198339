import { Injectable, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, catchError, concatMap, toArray, defaultIfEmpty, filter } from 'rxjs/operators';
import moment from 'moment';

import {
  ApiService, AuthService, Data, DataResponse, PAGE_SIZE, PAGE_SIZE_OPTIONS, PaginatedParams, SPF_DATE_FORMAT, SPF_TIME_FORMAT, UtilitiesService, WeekDay, WeekDays, Workout, WorkoutBlock,
  WorkoutBlockResponse,
  WorkoutCount,
  WorkoutCountResponse,
  WorkoutResponse, WorkoutsData, WorkoutsResponse, WorkoutTag, WorkoutTagResponse, WorkoutType, WorkoutTypesData, WorkoutTypesDataResponse, WorkoutView
} from 'sp-core';

import { WorkoutBlockService } from './workout-block.service';
import { addHours, addMinutes, startOfDay } from 'date-fns';

@Injectable()
export class WorkoutService {

  private lastWorkoutSubject$ = new Subject<Workout>();
  /**
   * /**
   * Escuchador de workout
   * ```
   * Obtiene el último workout agregado o modificado
   * ```
   */
  lastWorkout$ = this.lastWorkoutSubject$.asObservable();

  private workoutDeletedSubject$ = new Subject<Workout>();
  /**
   * /**
   * Escuchador de workout
   * ```
   * Obtiene el último workout eliminado
   * ```
   */
  workoutDeleted$ = this.workoutDeletedSubject$.asObservable();

  private workoutTypesDataSubject$ = new BehaviorSubject<WorkoutTypesData>(null);
  /**
   * Catálogo de tipos de workout
   */
  workoutTypesData$ = this.workoutTypesDataSubject$.asObservable();

  weekDayChange = new EventEmitter<WeekDay>();

  weekDaysChange = new EventEmitter<WeekDays>();

  private quickBuilderOpenRequestedSubject$ = new Subject<Workout>();
  /**
   * Observable para notificar que se ha solicitado visualizar o abrir la pantalla quick builder
   */
  quickBuilderOpenRequested$ = this.quickBuilderOpenRequestedSubject$.asObservable();

  private workoutClonedSubject$ = new Subject<Workout>();
  /**
   * Observable para notificar que se ha clonado un workout. Se diferencía del creado ya que éste considera los bloques asignados
   */
  workoutCloned$ = this.workoutClonedSubject$.asObservable();

  /**
   * Emite cuando la colección de bloques del workout ha sido modificado
   */
  blocks$ = new BehaviorSubject<Array<WorkoutBlock>>([]);

  /**
   * Emite cuando la colección de tags de la institución ha sido obtenido o actualizado
   */
  private tagsByInstitutionSubject$ = new BehaviorSubject<Array<WorkoutTag>>([]);
  tagsByInstitution$ = this.tagsByInstitutionSubject$.asObservable();

  private tagCreatedSubject$ = new Subject<WorkoutTag>();
  tagCreated$ = this.tagCreatedSubject$.asObservable();

  private tagDeletedSubject$ = new Subject<WorkoutTag>();
  tagDeleted$ = this.tagDeletedSubject$.asObservable();

  private tagUpdatedSubject$ = new Subject<WorkoutTag>();
  tagUpdated$ = this.tagUpdatedSubject$.asObservable();

  constructor(
    private api: ApiService,
    private blockService: WorkoutBlockService,
    private authService: AuthService
  ) { }

  /**
   * Obtiene la colección de workouts. Paginados
   * @param dayId Día de las rutinas a obtener
   * @param phaseId Fase de las rutinas a obtener
   * @param isFromLibrary Indica si el workout es de librería
   */
  getPaginated(
    dayId?: number,
    phaseId?: number,
    isFromLibrary?: boolean,
    tags?: Array<WorkoutTag>
  ): Observable<WorkoutsData> {

    let params = new HttpParams()
      .set('active', '1')
      .set('perpage', '5000');  // TODO: Verificar que funcione con 0 para obtener todos los registros

    if (dayId) {
      params = params.set('day', dayId.toString());
    }

    if (phaseId) {
      params = params.set('day__week__phase', phaseId.toString());
    }

    // Al ser tipo booleano se debe validar que no sea null o undefined.
    // Si se colocara únicamente como if (isFromLibrary) se consideraría como false si es null o undefined
    if (isFromLibrary !== null && isFromLibrary !== undefined) {
      params = params.set('has_library', isFromLibrary ? '1' : '0');
    }

    if (tags?.length) {
      params = params.set('tags__in', tags.map(x => x.id).join(','));
    }

    return this.api.get<WorkoutsResponse>(
      'workouts/',
      params
    ).pipe(
      map(response => {
        return new WorkoutsData().fromResponse(response)
      })
    );
  }

  /**
   * Obtiene la colección de workouts
   * @param dayId Día a obtener
   */
  get(
    dayId?: number,
    phaseId?: number,
    tags?: Array<WorkoutTag>
  ): Observable<Array<Workout>> {
    return this.getPaginated(
      dayId,
      phaseId,
      null,
      tags
    ).pipe(
      map(data => data.data)
    );
  }

  /**
   * Obtiene la colección de workouts de librería. Paginados y con pocos datos
   * @param [totalCount=false] Indica si se obtendrá el total de registros de bloques y ejercicios
   */
  getSimplePaginatedLibrary(
    params?: HttpParams,
    paginatedParams?: PaginatedParams,
    totalCount = false,
    folder?: string
  ): Observable<WorkoutsData> {

    if (!params) {
      params = new HttpParams();
    }

    if (paginatedParams) {
      params = paginatedParams.toRequest(params);
    }

    if (!params.get('tab')) {
      params = params.set('tab', 'all');
    }

    // Indica que se requiere el total de bloques y ejercicios
    if (totalCount) {
      params = params.set('total_count', true);
    }

    if(folder){
      params = params.set('list_folder', folder);
    }

    return this.api.get<WorkoutsResponse>(
      'workout-library/simple/',
      params
    ).pipe(
      map(response => new WorkoutsData().fromResponse(response))
    );
  }

  /**
   * Obtiene la colección de workouts de librería. Paginados
   */
  getPaginatedLibrary(
    params?: HttpParams,
    paginatedParams?: PaginatedParams
  ): Observable<WorkoutsData> {

    if (!params) {
      params = new HttpParams();
    }

    if (paginatedParams) {
      params = paginatedParams.toRequest(params);
    }

    if (!params.get('tab')) {
      params = params.set('tab', 'all');
    }

    return this.api
      .get<WorkoutsResponse>('workout-library/', params)
      .pipe(
        map(response => new WorkoutsData().fromResponse(response))
      );
  }

  getPDF(with_images: boolean, week: number, day?: number){
    return this.api.post('workout-builder-pdf-generator/',{with_images, week, day}, { responseType: 'blob' });
  }

  getLibrary(
    search?: string
  ): Observable<Array<Workout>> {

    let paginatedParams: PaginatedParams;
    if (search) {
      paginatedParams = new PaginatedParams();
      paginatedParams.search = search;
    }

    return this.getPaginatedLibrary(
      null,
      paginatedParams
    ).pipe(
      map(response => response.data)
    );
  }

  /**
   * Crea un nuevo workout
   * @param workout Datos de workout
   */
  create(workout: Workout): Observable<Workout> {
    return this.api
      .post<WorkoutResponse>('workouts/', workout.toCreateRequest())
      .pipe(map(response => {
        const workoutCreated = workout.fromResponse(response);
        this.lastWorkoutSubject$.next(workoutCreated);
        return workoutCreated;
      }));
  }

  /**
   * Crea un nuevo workout tipo librería
   */
  createLibrary(): Observable<Workout> {

    const workoutToCreate = new Workout();
    workoutToCreate.name = 'New workout';

    return this.api
      .post<WorkoutResponse>('workout-library/', workoutToCreate.toCreateRequest())
      .pipe(
        map(response => workoutToCreate.fromResponse(response))
      );
  }

  /**
   * Actualiza un workout
   * @param workout Datos de workout
   */
  update(workout: Workout): Observable<Workout> {

    const payload = workout.toCreateRequest();

    // Si no hay cambios no se envía la petición
    if (UtilitiesService.isEmptyObject(payload)) return of(workout);

    return this.api.patch<WorkoutResponse>(
      `workouts/${workout.id}/`,
      payload
    ).pipe(map(response => {
      workout.applyChanges();
      return workout.fromResponse(response);
    }));
  }

  updateTags(workout: Workout): Observable<Workout> {
    return this.api.patch<WorkoutResponse>(
      `workouts/${workout.id}/`,
      workout.toUpdateRequest()
    ).pipe(map(response => {
      workout.applyChanges();
      return workout.fromResponse(response);
    }));
  }

  /**
   * Elimina un workout de la BD en base al identificador
   * @param workout Workout a eliminar
   */
  deleteById(
    workoutId: number
  ): Observable<Workout> {

    const workout = new Workout();
    workout.id = workoutId;

    return this.api
      .delete(`workouts/${workoutId}/`)
      .pipe(
        map(() => {
          this.workoutDeletedSubject$.next(workout);
          return workout;
        })
      );
  }

  /**
   * Elimina un workout de la BD
   * @param workout Workout a eliminar
   */
  delete(
    workout: Workout
  ): Observable<Workout> {
    return this.api
      .delete(`workouts/${workout.id}/`)
      .pipe(
        map(() => {
          this.workoutDeletedSubject$.next(workout);
          return workout;
        })
      );
  }

  clone(workout: Workout): Observable<Workout> {

    const body = {
      model: 'workout'
    };
    if (workout.view === WorkoutView.workoutBuilder) {
      body['builder'] = true;
    }

    return this.api
      .post<WorkoutResponse>(
        `clone-library/${workout.id}/`, body
      ).pipe(
        map(response => new Workout().fromResponse(response)),
        mergeMap(workoutCloned => {
          // Obtiene los bloques asignados al workout ya que el clonado únicamente retorna el objeto principal del workout
          return this.blockService
            .get(workoutCloned.id, workoutCloned.hasLibrary)
            .pipe(
              map(blocks => {
                workoutCloned.view = workout.view;
                workoutCloned.blocks = blocks;
                this.workoutClonedSubject$.next(workoutCloned);
                return workoutCloned;
              })
            );
        })
      );
  }

  /**
   * Mueve o modifica la fecha de workout(s)
   * @param date Fecha en la que se moverán los workouts
   * @param workoutsIds Identificadores de workouts a mover
   * @returns 
   */
  move(
    date: Date,
    workoutsIds: Array<number>
  ): Observable<any> {

    const data = {
      date: moment(date).format('YYYY-MM-DD'),
      workouts: workoutsIds
    };

    return this.api
      .post(`move-workouts/`, data)
      .pipe(
        catchError(this.api.processError('WorkoutService.move')),
        map(response => response)
      )
  }

  calendarMove(
    workoutId: number,
    startDate: Date,
    endDate: Date
  ): Observable<Workout> {

    const data = {
      date: moment(startDate).format(SPF_DATE_FORMAT),
      start_time: moment(startDate).format(SPF_TIME_FORMAT),
      end_time: moment(endDate).format(SPF_TIME_FORMAT),
    }

    return this.api.patch<any>(
      `move-workout/${workoutId}/`,
      data
    ).pipe(
      map(response => {
        const updatedWorkoutResponse = response.data[0];
        const workout = new Workout();
        workout.id = updatedWorkoutResponse.id;
        workout.name = updatedWorkoutResponse.name;
        workout.startTime = addMinutes(addHours(startOfDay(new Date(updatedWorkoutResponse.date)), updatedWorkoutResponse.start.hour), updatedWorkoutResponse.start.minute);
        workout.endTime = addMinutes(addHours(startOfDay(new Date(updatedWorkoutResponse.date)), updatedWorkoutResponse.end.hour), updatedWorkoutResponse.end.minute);
        return workout;
      })
    );
  }

  /**
   * Se envía llamar a la pantalla quick builder
   * @param workout Workout
   */
  openQuickBuilder(workout: Workout): void {
    this.quickBuilderOpenRequestedSubject$.next(workout);
  }

  /**
   * Ordena los bloques indicados en el workout también especificado.
   * Caso 1: Si un bloque se mueve dentro del mismo workout aún así se indica el workout donde se encuentra 
   * Caso 2: Si un bloque se mueve a otro workout, se indica el workrout en el que se está asignando
   * @param workoutId Identificador del día en que se ordenarán los workouts
   * @param blocks Colección de bloques en el orden actual al que se desea ordenar
   * @returns No content
   */
  sortBlocksInWorkout(workoutId: number, blocks: Array<WorkoutBlock> = []): Observable<any> {
    return this.api.post(`blocks-sorting/${workoutId}/`, blocks.map(x => x.id));
  }

  saveAsTemplate(
    workout: Workout
  ): Observable<any> {

    const data = {
      name: workout.name
    }

    return this.api
      .post(`workouts/${workout.id}/library/`, data)
      .pipe(
        map(response => console.log(response))
      )
  }

  /**
   * Obtiene y asigna los bloques a cada uno de los workouts indicados
   * @param workouts Workouts a obtener sus bloques
   * @returns 
   */
  loadBlocks(workouts: Array<Workout>): Observable<Array<Workout>> {
    return from(workouts)
      .pipe(
        concatMap(workout => {
          return this.blockService.get(
            workout.id,
            workout.hasLibrary
          ).pipe(
            map(blocks => {
              workout.blocks = blocks;
              workout.blocks.forEach(block => block.workout = workout);
              return workout;
            })
          )
        }),
        toArray()
      );
  }

  /**
   * Importa bloques de librería al workout indicado
   * @param workout Workout en la que se importará(n) el o los bloques indicados
   * @param blocks Bloques a importar o asignar al workout indicado
   * @param blockIdToReplace Si se envía significa que se reemplazará el bloque indicado
   * @returns 
   */
  importLibraryBlocks(
    workout: Workout,
    blocks: Array<WorkoutBlock>,
    blockIdToReplace: number = null
  ): Observable<Array<WorkoutBlock>> {

    const data = {
      blocks: blocks.map(x => x.id),
      replace: blockIdToReplace
    };

    return this.api
      .post<Array<WorkoutBlockResponse>>(
        `block-library-import/${workout.id}/`,
        data
      ).pipe(
        map(response => response.map(x => new WorkoutBlock().fromResponse(x))),
        // Por cada bloque actualiza el indicador all_users
        mergeMap(blocks => {
          return forkJoin(
            blocks.map(block => {
              block.allAthletes = true;
              return this.blockService.update(block);
            })
          ).pipe(
            defaultIfEmpty<Array<WorkoutBlock>>([])
          );
        }),
        // Por cada bloque importado asigna el workout para acceso a los atletas asignados
        map(blocks => {
          blocks.forEach(block => block.workout = workout);
          return blocks;
        })
      );
  }

  getPaginatedWorkoutTypes(): Observable<WorkoutTypesData> {
    return this.api.get<WorkoutTypesDataResponse>(
      'place-catalog/'
    ).pipe(
      map(response => {
        const workoutTypesData = new WorkoutTypesData().fromResponse(response);
        this.workoutTypesDataSubject$.next(workoutTypesData);
        return workoutTypesData;
      })
    )
  }

  getWorkoutTypes(): Observable<Array<WorkoutType>> {
    return this.getPaginatedWorkoutTypes().pipe(
      map(response => response.data)
    )
  }

  /**
   * Obtiene los workouts de la fecha indicada
   * @param date Fecha de los workouts a obtener
   * @param tab Indica si se obtendrá con el perfil coach o atleta
   * @returns 
   */
  getTodayWorkouts(
    date: Date,
    tab: 'coach' | 'athlete' = 'coach',
    userId?: number
  ): Observable<Array<Workout>> {

    let params = new HttpParams()
      .set('date', moment(date).format(SPF_DATE_FORMAT))
      .set('tab', tab);

    if (userId) {
      params = params.set('user', userId.toString());
    }

    return this.api.get<Array<WorkoutResponse>>(
      `today-workout`,
      params
    ).pipe(
      map(response => response.map(x => new Workout().fromResponse(x)))
    );
  }

  getWorkoutsCount(
    fromDate: Date,
    toDate: Date,
    athleteId: number
  ): Observable<Array<WorkoutCount>> {

    const params = new HttpParams()
      .set('start', moment(fromDate).format(SPF_DATE_FORMAT))
      .set('end', moment(toDate).format(SPF_DATE_FORMAT))
      .set('athlete', athleteId.toString());

    return this.api.get<Array<WorkoutCountResponse>>(
      'workout-count/',
      params
    ).pipe(
      map(response => response.map(x => WorkoutCount.fromResponse(x)))
    )
  }

  getPaginatedTagsByInstitution(
    institutionId?: number,
    paginatedParams?: PaginatedParams
  ): Observable<Data<WorkoutTag>> {

    let params = new HttpParams();

    if (!institutionId) {
      institutionId = this.authService.institutionId;
    }
    params = params.set('institution', institutionId.toString());

    if (!paginatedParams) {
      paginatedParams = new PaginatedParams();
      paginatedParams.page = 1;
      paginatedParams.pageSize = PAGE_SIZE;
    }
    params = paginatedParams?.toRequest(params);

    return this.api.get<DataResponse<WorkoutTagResponse>>(
      `tags-workout/`,
      params
    ).pipe(
      catchError(this.api.processError('WorkoutService.getTagsByInstitution')),
      map((response: DataResponse<WorkoutTagResponse>) => {
        return response ? new Data(WorkoutTag).fromResponse(response) : null;
      })
    );
  }

  getTagsByInstitution(
    institutionId?: number
  ): Observable<Array<WorkoutTag>> {

    // Paginated params to bring all tags
    const params = new PaginatedParams();
    params.page = 1;
    params.pageSize = 10000;

    return this.getPaginatedTagsByInstitution(
      institutionId,
      params
    ).pipe(
      map(response => {
        const tags = WorkoutTag.sortByName(response?.data || []);
        this.tagsByInstitutionSubject$.next(tags);
        return tags;
      })
    );
  }

  /**
   * Create a new institutional tag
   * ```
   * The institution id is sent globally in token interceptor
   * ```
   * @param tagName 
   * @returns 
   */
  createTag(tagName: string): Observable<any> {

    const tagToCreate = new WorkoutTag();
    tagToCreate.name = tagName;

    return this.api.post<WorkoutTagResponse>(
      'tags-workout/',
      WorkoutTag.toRequest(tagToCreate)
    ).pipe(
      catchError(this.api.processError('WorkoutService.createTag')),
      map((response: WorkoutTagResponse) => {
        const createdWorkout = WorkoutTag.fromResponse(response);
        this.tagCreatedSubject$.next(createdWorkout);
        return createdWorkout;
      })
    );
  }

  /**
   * Update a institutional tag
   * @param tag 
   * @returns 
   */
  updateTag(tag: WorkoutTag): Observable<boolean> {

    return this.api.patch<WorkoutTagResponse>(
      `tags-workout/${tag.id}/`,
      tag.toUpdateRequest()
    ).pipe(
      catchError(this.api.processError('WorkoutService.updateTag')),
      map(() => {
        this.tagUpdatedSubject$.next(tag);
        return true;
      })
    );
  }

  /**
   * Delete a institutional tag
   * @param tag 
   * @returns 
   */
  deleteTag(tag: WorkoutTag): Observable<boolean> {

    return this.api.delete<WorkoutTagResponse>(
      `tags-workout/${tag.id}/`
    ).pipe(
      catchError(this.api.processError('WorkoutService.deleteTag')),
      map(() => {
        this.tagDeletedSubject$.next(tag);
        return true;
      })
    );
  }
}